import { useState } from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDEditor from "components/MDEditor";
import CustomAutoSearch from "components/CustomAutoSearch";
import CustomDropZone from "components/CustomDropZone";
import MDTypography from "components/MDTypography";
import CustomErrorMessage from "components/CustomErrorMessage";
import MDIconButton from "components/MDIconButton";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import InputLabel from "@mui/material/InputLabel";
import { useAppDispatch, useAppSelector } from "store/store";
import { addLocation } from "store/thunk/locationThunk";
import constants from "helper/constants";
import Validation from "helper/validations";
import {
  scrollToErrorByClass,
  getPermission,
  adminSuperAdmin,
  makeURLName,
  adminFranchisie,
} from "helper/services";
import CustomPhoneInput from "components/CustomPhoneInput";
import fileimg from "assets/images/file-image.svg";
import { Autocomplete, TextField } from "@mui/material";

function AddUpdate(props: any): JSX.Element {
  const { open, onClose, location, pageNo, perPage, search, sortBy } = props;
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.authReducer);
  const [errors, setErrors] = useState<any>({});
  const [locationDetail, setLocationDetail] = useState<any>({});

  const onSave = () => {
    const errors = Validation.validateAddLocationWizard(locationDetail);
    console.log("errors:", errors);
    setErrors(errors);
    if (!Object.keys(errors).length) {
      const request = {
        location: locationDetail,
        callback: onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(addLocation(request));
    } else {
      scrollToErrorByClass(errors);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: `${location && location?.location_id ? "Update" : "Add"}  Location`,
    size: "xl",
  };

  const onChangeField = (event: any) => {
    setLocationDetail({ ...locationDetail, [event.target.name]: event.target.value });
  };

  const onChangeName = (event: any) => {
    setLocationDetail({
      ...locationDetail,
      [event.target.name]: event.target.value,
      url_name: event.target.value ? makeURLName(event.target.value) : "",
    });
  };

  const onChangeDescription = (val: any) => {
    setLocationDetail({ ...locationDetail, description: val });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setLocationDetail({ ...locationDetail, [name]: value });
  };

  const onChangeLease = (files: any) => {
    setLocationDetail({ ...locationDetail, signed_lease: files && files[0] ? files[0] : null });
  };

  // Add select for Region
  const displayRegion = [
    { value: "east", name: "East" },
    { value: "central", name: "Central" },
    { value: "west", name: "West" },
  ];
  return (
    <MDDialog {...dialogProps}>
      <MDBox>
        <MDBox>
          <Accordion expanded>
            <AccordionSummary className="location-accordion">
              <MDTypography>General</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Location Name"
                      variant="standard"
                      fullWidth
                      name="name"
                      placeholder="Location Name"
                      onChange={onChangeName}
                      value={locationDetail?.name || ""}
                      error={errors && errors.name ? true : false}
                      helperText={errors && errors.name}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="URL Name"
                      variant="standard"
                      fullWidth
                      name="url_name"
                      placeholder="URL Name"
                      onChange={onChangeField}
                      value={locationDetail?.url_name || ""}
                      error={errors && errors.url_name ? true : false}
                      helperText={errors && errors.url_name}
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} className="description">
                    <MDTypography variant="button">Description</MDTypography>
                    <MDEditor
                      value={locationDetail?.description || ""}
                      onChange={(val: any) => onChangeDescription(val)}
                      modules={constants.editorModules}
                      formats={constants.editorFormats}
                    />
                    {errors && errors.description && (
                      <CustomErrorMessage message={errors?.description} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} className="association">
                    <CustomAutoSearch
                      idKey="id"
                      nameKey="association_name"
                      fieldLabel="Association"
                      apiName="get-associations-list"
                      responseKey="data"
                      name="association"
                      onChange={onChangeAutoComplete}
                      value={locationDetail?.association || []}
                      usersData={{ user_id: userData?.user_id }}
                      error={errors && errors.association}
                      slsAPI
                      multiple
                      createNewLink={
                        getPermission("locations", "locations_associations")
                          ? "/locations/associations"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} className="mas">
                    <CustomAutoSearch
                      idKey="id"
                      multiple
                      nameKey="name"
                      fieldLabel="MSA"
                      apiName="msas"
                      responseKey="msas"
                      name="msas"
                      value={locationDetail?.msas}
                      onChange={onChangeAutoComplete}
                      createNewLink={
                        getPermission("locations", "locations_msa") ? "/locations/msa" : ""
                      }
                      required
                      error={errors && errors.msas}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Sola ID"
                      variant="standard"
                      fullWidth
                      name="store_id"
                      placeholder="Sola ID"
                      onChange={onChangeField}
                      value={locationDetail?.store_id || ""}
                      // error={errors && errors.store_id ? true : false}
                      // helperText={errors && errors.store_id}
                      // required
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                    <CustomAutoSearch
                      idKey="LocationID"
                      nameKey="Name"
                      fieldLabel="RM Location"
                      apiName="get-rm-locations-list"
                      responseKey="data"
                      name="rent_manager_location"
                      value={locationDetail?.rent_manager_location || null}
                      onChange={onChangeAutoComplete}
                      error={errors && errors.rent_manager_location}
                      slsAPI
                    />
                  </Grid> */}
                  {adminSuperAdmin() && (
                    <>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Autocomplete
                          options={displayRegion}
                          value={locationDetail?.region || null}
                          getOptionLabel={(option) => option.name}
                          onChange={(event: any, newValue: any) => {
                            setLocationDetail({ ...locationDetail, region: newValue });
                          }}
                          renderOption={(props: any, option: any) => (
                            <li {...props}>{option.name}</li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Region"
                              placeholder="Region"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <MDInput
                          label="FBC"
                          variant="standard"
                          fullWidth
                          name="fbc"
                          placeholder="FBC"
                          onChange={onChangeField}
                          value={locationDetail?.fbc || ""}
                        />
                      </Grid>
                      {false && (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <CustomAutoSearch
                            idKey="id"
                            nameKey="title"
                            fieldLabel="Blog"
                            apiName="blogs"
                            responseKey="blogs"
                            name="blogs"
                            value={locationDetail?.blogs || ""}
                            onChange={onChangeAutoComplete}
                            multiple
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </MDBox>
            </AccordionDetails>
          </Accordion>
        </MDBox>
        <MDBox mt={2}>
          <Accordion expanded>
            <AccordionSummary className="location-accordion">
              <MDTypography>General Contact Info</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="First name"
                      variant="standard"
                      fullWidth
                      name="general_contact_first_name"
                      placeholder="First name"
                      onChange={onChangeField}
                      value={locationDetail?.general_contact_first_name || ""}
                      error={errors && errors.general_contact_first_name ? true : false}
                      helperText={errors && errors.general_contact_first_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Last name"
                      variant="standard"
                      fullWidth
                      name="general_contact_last_name"
                      placeholder="Last name"
                      onChange={onChangeField}
                      value={locationDetail?.general_contact_last_name || ""}
                      error={errors && errors.general_contact_last_name ? true : false}
                      helperText={errors && errors.general_contact_last_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Email"
                      variant="standard"
                      fullWidth
                      name="general_contact_email"
                      placeholder="Email"
                      onChange={onChangeField}
                      value={locationDetail?.general_contact_email || ""}
                      error={errors && errors.general_contact_email ? true : false}
                      helperText={errors && errors.general_contact_email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <InputLabel>Phone</InputLabel>
                    <MDInput
                      variant="standard"
                      fullWidth
                      name="general_contact_phone"
                      placeholder="(000) 000-0000"
                      id="phone-input"
                      InputProps={{
                        inputComponent: CustomPhoneInput as any,
                      }}
                      value={locationDetail?.general_contact_phone}
                      error={errors && errors.general_contact_phone ? true : false}
                      helperText={errors && errors.general_contact_phone}
                      onChange={onChangeField}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </AccordionDetails>
          </Accordion>
        </MDBox>
        <MDBox mt={2}>
          <Accordion expanded>
            <AccordionSummary className="location-accordion">
              <MDTypography>Other Contact Info</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Email Address For Inquiries"
                      variant="standard"
                      fullWidth
                      name="email_address_for_inquiries"
                      placeholder="Email Address For Inquiries"
                      onChange={onChangeField}
                      value={locationDetail?.email_address_for_inquiries || ""}
                      error={errors && errors.email_address_for_inquiries ? true : false}
                      helperText={errors && errors.email_address_for_inquiries}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Email Address For Reports"
                      variant="standard"
                      fullWidth
                      name="email_address_for_reports"
                      placeholder="Email Address For Reports"
                      onChange={onChangeField}
                      value={locationDetail?.email_address_for_reports || ""}
                      error={errors && errors.email_address_for_reports ? true : false}
                      helperText={errors && errors.email_address_for_reports}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Email Address For Hubspot"
                      variant="standard"
                      fullWidth
                      name="email_address_for_hubspot"
                      placeholder="Email Address For Hubspot"
                      onChange={onChangeField}
                      value={locationDetail?.email_address_for_hubspot || ""}
                      error={errors && errors.email_address_for_hubspot ? true : false}
                      helperText={errors && errors.email_address_for_hubspot}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Email Addresses For Stylist Website Approvals"
                      variant="standard"
                      fullWidth
                      name="emails_for_stylist_website_approvals"
                      placeholder="Email Addresses For Stylist Website Approvals"
                      onChange={onChangeField}
                      value={locationDetail?.emails_for_stylist_website_approvals || ""}
                      error={errors && errors.emails_for_stylist_website_approvals ? true : false}
                      helperText={errors && errors.emails_for_stylist_website_approvals}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </AccordionDetails>
          </Accordion>
        </MDBox>
        <MDBox mt={2}>
          <Accordion expanded>
            <AccordionSummary className="location-accordion">
              <MDTypography>Address</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Address 1"
                      variant="standard"
                      fullWidth
                      name="address_1"
                      placeholder="Address 1"
                      onChange={onChangeField}
                      value={locationDetail?.address_1 || ""}
                      error={errors && errors.address_1 ? true : false}
                      helperText={errors && errors.address_1}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Address 2"
                      variant="standard"
                      fullWidth
                      name="address_2"
                      placeholder="Address 2"
                      onChange={onChangeField}
                      value={locationDetail?.address_2 || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="City"
                      variant="standard"
                      fullWidth
                      name="city"
                      placeholder="City"
                      onChange={onChangeField}
                      value={locationDetail?.city || ""}
                      error={errors && errors.city ? true : false}
                      helperText={errors && errors.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="Postal Code"
                      variant="standard"
                      fullWidth
                      type="number"
                      name="postal_code"
                      placeholder="Postal Code"
                      onChange={onChangeField}
                      value={locationDetail?.postal_code || ""}
                      error={errors && errors.postal_code ? true : false}
                      helperText={errors && errors.postal_code}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MDInput
                      label="State/Province"
                      variant="standard"
                      fullWidth
                      name="state"
                      placeholder="State/Province"
                      onChange={onChangeField}
                      value={locationDetail?.state || ""}
                      error={errors && errors.state}
                      helperText={errors && errors.state}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} className="country">
                    <CustomAutoSearch
                      idKey="id"
                      nameKey="name"
                      fieldLabel="Countries"
                      apiName="countries"
                      responseKey="countries"
                      name="country"
                      value={locationDetail?.country || null}
                      onChange={onChangeAutoComplete}
                      error={errors && errors.country}
                      helperText={errors && errors.country}
                      required
                    />
                  </Grid>
                  {/* SD-3391 */}
                  {adminFranchisie() && (
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <MDInput
                        label="Map / GMB URL"
                        variant="standard"
                        fullWidth
                        name="gmb_map_url"
                        placeholder="Map / GMB URL"
                        onChange={onChangeField}
                        value={locationDetail?.gmb_map_url || ""}
                        error={errors && errors.gmb_map_url ? true : false}
                        helperText={errors && errors.gmb_map_url}
                      />
                    </Grid>
                  )}
                </Grid>
              </MDBox>
            </AccordionDetails>
          </Accordion>
        </MDBox>
        <MDBox mt={2}>
          <Accordion expanded>
            <AccordionSummary className="location-accordion">
              <MDTypography>Social</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <MDInput
                      label="Facebook URL"
                      variant="standard"
                      fullWidth
                      name="facebook_url"
                      placeholder="Facebook URL"
                      onChange={onChangeField}
                      value={locationDetail?.facebook_url || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <MDInput
                      label="Instagram URL"
                      variant="standard"
                      fullWidth
                      name="instagram_url"
                      placeholder="Instagram URL"
                      onChange={onChangeField}
                      value={locationDetail?.instagram_url || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <MDInput
                      label="Twitter URL"
                      variant="standard"
                      fullWidth
                      name="twitter_url"
                      placeholder="Twitter URL"
                      onChange={onChangeField}
                      value={locationDetail?.twitter_url || ""}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </AccordionDetails>
          </Accordion>
        </MDBox>
        <MDBox mt={1}>
          <Accordion expanded>
            <AccordionSummary className="location-accordion">
              <MDTypography>Lease</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox>
                <Grid container spacing={2} className="signed_lease">
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel sx={{ mb: 1 }}>Signed Lease </InputLabel>
                    <CustomDropZone
                      multipleUpload={false}
                      acceptedFiles={{
                        "application/pdf": [".pdf"],
                      }}
                      onDrop={onChangeLease}
                    >
                      Drop your file here, or Browse
                    </CustomDropZone>
                    {errors && errors?.signed_lease && (
                      <CustomErrorMessage message={errors?.signed_lease} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {locationDetail.signed_lease && (
                      <Grid container mt={2}>
                        <Grid item className="imageWithDeleteIcon">
                          <img height={100} width={100} src={fileimg} alt="lease" />
                          <MDIconButton
                            tooltipName="Delete"
                            aria-label="Delete"
                            className="removeBtnClass"
                            onClick={() => onChangeLease(null)}
                          >
                            <Icon fontSize="small">close</Icon>
                          </MDIconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
            </AccordionDetails>
          </Accordion>
        </MDBox>
      </MDBox>
    </MDDialog>
  );
}

export default AddUpdate;
