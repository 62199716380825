export default {
  rent_manager_units: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "rm_unit_id",
        label: "Rm unit",
        common: false,
      },
      {
        key: "rm_property_id",
        label: "Rm property id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "comment",
        label: "Comment",
        common: false,
      },
      {
        key: "rm_unit_type_id",
        label: "Rm unit type",
        common: false,
      },
      {
        key: "rm_location_id",
        label: "Rm location",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Rent manager units",
    table_name: "rent_manager_units",
  },
  events: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "category",
        label: "Category",
        common: false,
      },
      {
        key: "action",
        label: "Action",
        common: false,
      },
      {
        key: "platform",
        label: "Platform",
        common: false,
      },
      {
        key: "userable_id",
        label: "Userable id",
        common: false,
      },
      {
        key: "userable_type",
        label: "Userable type",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Events",
    table_name: "events",
  },
  gloss_genius_logs: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "action_name",
        label: "Action name",
        common: false,
      },
      {
        key: "ip_address",
        label: "Ip address",
        common: false,
      },
      {
        key: "host",
        label: "Host",
        common: false,
      },
      {
        key: "request_body",
        label: "Request body",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Gloss genius logs",
    table_name: "gloss_genius_logs",
  },
  request_tour_inquiries: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: true,
      },
      {
        key: "email",
        label: "Email",
        common: true,
      },
      {
        key: "phone",
        label: "Phone",
        common: true,
      },
      {
        key: "location_name",
        label: "Location",
        common: true,
      },
      {
        key: "message",
        label: "message",
        common: true,
      },
      {
        key: "how_can_we_help_you",
        label: "How can we help you",
        common: true,
      },
      {
        key: "contact_preference",
        label: "Contact preference",
        common: false,
      },
      {
        key: "services",
        label: "Services",
        common: false,
      },
      {
        key: "dont_see_your_location",
        label: "Dont see your location",
        common: false,
      },
      {
        key: "state",
        label: "State",
        common: false,
      },
      {
        key: "zip_code",
        label: "Zip code",
        common: false,
      },
      {
        key: "request_url",
        label: "Request url",
        common: false,
      },
      {
        key: "send_email_to_prospect",
        label: "Prospect Origin",
        common: false,
      },
      {
        key: "utm_source",
        label: "UTM source",
        common: false,
      },
      {
        key: "utm_medium",
        label: "UTM medium",
        common: false,
      },
      {
        key: "utm_campaign",
        label: "UTM campaign",
        common: false,
      },
      {
        key: "utm_content",
        label: "UTM content",
        common: false,
      },
      {
        key: "newsletter",
        label: "Newsletter",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: true,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "session_id",
        label: "Session Id",
        common: false,
      },
      {
        key: "allow_team_to_text_you",
        label: "SMS Opt In",
        common: false,
      },
    ],
    export_type: "Contacts Inquiries",
    table_name: "request_tour_inquiries",
    join_tables: [
      {
        table: "locations",
        fields: ["name"],
        first_table_join_field: "location_id",
        second_table_join_field: "id",
      },
    ],
  },
  maintenance_contacts: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "location_id",
        label: "Location",
        common: false,
      },
      {
        key: "contact_type",
        label: "Contact type",
        common: false,
      },
      {
        key: "contact_order",
        label: "Contact order",
        common: false,
      },
      {
        key: "contact_first_name",
        label: "Contact first name",
        common: false,
      },
      {
        key: "contact_last_name",
        label: "Contact last name",
        common: false,
      },
      {
        key: "contact_phone_number",
        label: "Contact phone number",
        common: false,
      },
      {
        key: "contact_email",
        label: "Contact email",
        common: false,
      },
      {
        key: "contact_admin",
        label: "Contact admin",
        common: false,
      },
      {
        key: "contact_preference",
        label: "Contact preference",
        common: false,
      },
      {
        key: "request_routing_url",
        label: "Request routing url",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Maintenance Contacts",
    table_name: "maintenance_contacts",
  },
  my_sola_images: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "instagram_handle",
        label: "Instagram handle",
        common: false,
      },
      {
        key: "statement",
        label: "Statement",
        common: false,
      },
      {
        key: "approved",
        label: "Approved",
        common: false,
      },
      {
        key: "approved_at",
        label: "Approved at",
        common: false,
      },
      {
        key: "original_image_url",
        label: "Image",
        common: false,
      },
      {
        key: "statement_variant",
        label: "Statement variant",
        common: false,
      },
      {
        key: "public_id",
        label: "Public",
        common: false,
      },
      {
        key: "generated_image_url",
        label: "Generated image",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "My Sola Images",
    table_name: "my_sola_images",
  },
  connect_users: {
    export_fields: [
      {
        key: "user_id",
        label: "User id",
        common: false,
      },
      {
        key: "first_name",
        label: "First name",
        common: false,
      },
      {
        key: "last_name",
        label: "Last name",
        common: false,
      },
      {
        key: "login_email",
        label: "Login email",
        common: false,
      },
      {
        key: "system_admin",
        label: "System admin",
        common: false,
      },
      {
        key: "last_login",
        label: "Last login",
        common: false,
      },
      {
        key: "sso_google",
        label: "Sso google",
        common: false,
      },
      {
        key: "sso_microsoft",
        label: "Sso microsoft",
        common: false,
      },
      {
        key: "locked",
        label: "Locked",
        common: false,
      },
      {
        key: "failed_attempt",
        label: "Failed attempt",
        common: false,
      },
      {
        key: "verified",
        label: "Verified",
        common: false,
      },
      {
        key: "sms_phone",
        label: "Sms phone",
        common: false,
      },
      {
        key: "login_count",
        label: "Login count",
        common: false,
      },
      {
        key: "profile_picture",
        label: "Profile picture",
        common: false,
      },
      {
        key: "deleted",
        label: "Deleted",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Connect Users",
    table_name: "connect_users",
  },
  admins: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "email",
        label: "Email",
        common: false,
      },
      {
        key: "reset_password_sent_at",
        label: "Reset password sent at",
        common: false,
      },
      {
        key: "remember_created_at",
        label: "Remember created at",
        common: false,
      },
      {
        key: "sign_in_count",
        label: "Sign in count",
        common: false,
      },
      {
        key: "current_sign_in_at",
        label: "Current sign in at",
        common: false,
      },
      {
        key: "last_sign_in_at",
        label: "Last sign in at",
        common: false,
      },
      {
        key: "current_sign_in_ip",
        label: "Current sign in ip",
        common: false,
      },
      {
        key: "last_sign_in_ip",
        label: "Last sign in ip",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "franchisee",
        label: "Franchisee",
        common: false,
      },
      {
        key: "legacy_id",
        label: "Legacy",
        common: false,
      },
      {
        key: "email_address",
        label: "Email address",
        common: false,
      },
      {
        key: "forgot_password_key",
        label: "Forgot password key",
        common: false,
      },
      {
        key: "mailchimp_api_key",
        label: "Mailchimp api key",
        common: false,
      },
      {
        key: "callfire_app_login",
        label: "Callfire app login",
        common: false,
      },
      {
        key: "callfire_app_password",
        label: "Callfire app password",
        common: false,
      },
      {
        key: "sola_pro_country_admin",
        label: "Sola pro country admin",
        common: false,
      },
      {
        key: "onboarded",
        label: "Onboarded",
        common: false,
      },
    ],
    export_type: "Lagacy Admin",
    table_name: "admins",
  },
  reports: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "report_type",
        label: "Report type",
        common: false,
      },
      {
        key: "email_address",
        label: "Email address",
        common: false,
      },
      {
        key: "processed_at",
        label: "Processed at",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "parameters",
        label: "Parameters",
        common: false,
      },
      {
        key: "subject",
        label: "Subject",
        common: false,
      },
    ],
    export_type: "Reports",
    table_name: "reports",
  },
  msas: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "legacy_id",
        label: "Legacy",
        common: false,
      },
      {
        key: "description",
        label: "Description",
        common: false,
      },
      {
        key: "tracking_code",
        label: "Tracking code",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "MSAs",
    table_name: "msas",
  },
  book_now_bookings: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "time_range",
        label: "Time range",
        common: false,
      },
      {
        key: "location_id",
        label: "Location",
        common: false,
      },
      {
        key: "query",
        label: "Query",
        common: false,
      },
      {
        key: "services",
        label: "Services",
        common: false,
      },
      {
        key: "stylist_id",
        label: "Stylist",
        common: false,
      },
      {
        key: "booking_user_name",
        label: "Booking user name",
        common: false,
      },
      {
        key: "booking_user_phone",
        label: "Booking user phone",
        common: false,
      },
      {
        key: "booking_user_email",
        label: "Booking user email",
        common: false,
      },
      {
        key: "referring_url",
        label: "Referring url",
        common: false,
      },
      {
        key: "total",
        label: "Total",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "location_name",
        label: "Location",
        common: false,
      },
    ],
    export_type: "Book Now Bookings",
    table_name: "book_now_bookings",
  },
  partner_inquiries: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "subject",
        label: "Subject",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "company_name",
        label: "Company name",
        common: false,
      },
      {
        key: "email",
        label: "email",
        common: false,
      },
      {
        key: "phone",
        label: "Phone",
        common: false,
      },
      {
        key: "message",
        label: "Message",
        common: false,
      },

      {
        key: "request_url",
        label: "Request url",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Partner Inquiries",
    table_name: "partner_inquiries",
  },
  sola10k_images: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "instagram_handle",
        label: "Instagram handle",
        common: false,
      },
      {
        key: "statement",
        label: "Statement",
        common: false,
      },
      {
        key: "approved",
        label: "Approved",
        common: false,
      },
      {
        key: "approved_at",
        label: "Approved at",
        common: false,
      },

      {
        key: "public_id",
        label: "Public",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image file name",
        common: false,
      },
      {
        key: "generated_image_file_name",
        label: "Generated image file name",
        common: false,
      },
      {
        key: "color",
        label: "Color",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Sola10k Images",
    table_name: "sola10k_images",
  },
  stylist_messages: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "email",
        label: "Email",
        common: false,
      },
      {
        key: "phone",
        label: "Phone",
        common: false,
      },
      {
        key: "message",
        label: "Message",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Stylist Messages",
    table_name: "stylist_messages",
  },
  stylist_units: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "rm_lease_id",
        label: "Rm lease",
        common: false,
      },
      {
        key: "move_in_at",
        label: "Move in at",
        common: false,
      },
      {
        key: "move_out_at",
        label: "Move out at",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Rent Manager Stylist Units",
    table_name: "stylist_units",
  },
  testimonials: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "text",
        label: "Text",
        common: false,
      },
      {
        key: "region",
        label: "Region",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Testimonials",
    table_name: "testimonials",
  },
  external_ids: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "objectable_id",
        label: "Objectable id",
        common: false,
      },
      {
        key: "objectable_type",
        label: "Objectable type",
        common: false,
      },
      {
        key: "kind",
        label: "Kind",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "value",
        label: "Value",
        common: false,
      },
      {
        key: "rm_location_id",
        label: "Rm location",
        common: false,
      },
      {
        key: "active_start_date",
        label: "Active start date",
        common: false,
      },
      {
        key: "active_end_date",
        label: "Active end date",
        common: false,
      },
      {
        key: "matching_category",
        label: "Matching category",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Externals",
    table_name: "externals",
  },
  update_my_sola_websites: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "biography",
        label: "Biography",
        common: false,
      },
      {
        key: "phone_number",
        label: "Phone number",
        common: false,
      },
      {
        key: "business_name",
        label: "Business ame",
        common: false,
      },
      {
        key: "work_hours",
        label: "Work hours",
        common: false,
      },
      {
        key: "hair",
        label: "Hair",
        common: false,
      },
      {
        key: "skin",
        label: "Skin",
        common: false,
      },
      {
        key: "nails",
        label: "Nails",
        common: false,
      },
      {
        key: "massage",
        label: "Massage",
        common: false,
      },
      {
        key: "teeth_whitening",
        label: "Teeth whitening",
        common: false,
      },
      {
        key: "eyelash_extensions",
        label: "Eyelash extensions",
        common: false,
      },
      {
        key: "makeup",
        label: "Makeup",
        common: false,
      },
      {
        key: "tanning",
        label: "Tanning",
        common: false,
      },
      {
        key: "waxing",
        label: "Waxing",
        common: false,
      },
      {
        key: "brows",
        label: "Brows",
        common: false,
      },
      {
        key: "website_url",
        label: "Website url",
        common: false,
      },
      {
        key: "booking_url",
        label: "Booking url",
        common: false,
      },
      {
        key: "pinterest_url",
        label: "Pinterest url",
        common: false,
      },
      {
        key: "facebook_url",
        label: "Facebook url",
        common: false,
      },
      {
        key: "twitter_url",
        label: "Twitter url",
        common: false,
      },
      {
        key: "instagram_url",
        label: "Instagram url",
        common: false,
      },
      {
        key: "yelp_url",
        label: "Yelp url",
        common: false,
      },
      {
        key: "laser_hair_removal",
        label: "Laser hair removal",
        common: false,
      },
      {
        key: "threading",
        label: "Threading",
        common: false,
      },
      {
        key: "permanent_makeup",
        label: "Permanent makeup",
        common: false,
      },
      {
        key: "other_service",
        label: "Other service",
        common: false,
      },
      {
        key: "google_plus_url",
        label: "Google plus url",
        common: false,
      },
      {
        key: "linkedin_url",
        label: "Linkedin url",
        common: false,
      },
      {
        key: "hair_extensions",
        label: "Hair extensions",
        common: false,
      },
      {
        key: "image_1_url",
        label: "Image 1 url",
        common: false,
      },
      {
        key: "image_2_url",
        label: "Image 2 url",
        common: false,
      },
      {
        key: "image_3_url",
        label: "Image 3 url",
        common: false,
      },
      {
        key: "image_4_url",
        label: "Image 4 url",
        common: false,
      },
      {
        key: "image_5_url",
        label: "Image 5 url",
        common: false,
      },
      {
        key: "image_6_url",
        label: "Image 6 url",
        common: false,
      },
      {
        key: "image_7_url",
        label: "Image 7 url",
        common: false,
      },
      {
        key: "image_8_url",
        label: "Image 8 url",
        common: false,
      },
      {
        key: "image_9_url",
        label: "Image 9 url",
        common: false,
      },
      {
        key: "image_10_url",
        label: "Image 10 url",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "email_address",
        label: "Email address",
        common: false,
      },
      {
        key: "approved",
        label: "Approved",
        common: false,
      },
      {
        key: "image_1_file_name",
        label: "image 1",
        common: false,
      },
      {
        key: "image_2_file_name",
        label: "image 2",
        common: false,
      },
      {
        key: "image_3_file_name",
        label: "image 3",
        common: false,
      },
      {
        key: "image_4_file_name",
        label: "image 4",
        common: false,
      },
      {
        key: "image_5_file_name",
        label: "image 5",
        common: false,
      },
      {
        key: "image_6_file_name",
        label: "image 6",
        common: false,
      },
      {
        key: "image_7_file_name",
        label: "image 7",
        common: false,
      },
      {
        key: "image_8_file_name",
        label: "image 8",
        common: false,
      },
      {
        key: "image_9_file_name",
        label: "image 9",
        common: false,
      },
      {
        key: "image_10_file_name",
        label: "image 10",
        common: false,
      },
      {
        key: "microblading",
        label: "Microblading",
        common: false,
      },
      {
        key: "reserved",
        label: "Reserved",
        common: false,
      },
      {
        key: "botox",
        label: "Botox",
        common: false,
      },
      {
        key: "tik_tok_url",
        label: "Tik tok url",
        common: false,
      },
      {
        key: "barber",
        label: "Barber",
        common: false,
      },
    ],
    export_type: "Update My Sola Websites",
    table_name: "update_my_sola_websites",
  },
  update_my_sola_website_deleted_records: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "biography",
        label: "Biography",
        common: false,
      },
      {
        key: "phone_number",
        label: "Phone number",
        common: false,
      },
      {
        key: "business_name",
        label: "Business ame",
        common: false,
      },
      {
        key: "work_hours",
        label: "Work hours",
        common: false,
      },
      {
        key: "hair",
        label: "Hair",
        common: false,
      },
      {
        key: "skin",
        label: "Skin",
        common: false,
      },
      {
        key: "nails",
        label: "Nails",
        common: false,
      },
      {
        key: "massage",
        label: "Massage",
        common: false,
      },
      {
        key: "teeth_whitening",
        label: "Teeth whitening",
        common: false,
      },
      {
        key: "eyelash_extensions",
        label: "Eyelash extensions",
        common: false,
      },
      {
        key: "makeup",
        label: "Makeup",
        common: false,
      },
      {
        key: "tanning",
        label: "Tanning",
        common: false,
      },
      {
        key: "waxing",
        label: "Waxing",
        common: false,
      },
      {
        key: "brows",
        label: "Brows",
        common: false,
      },
      {
        key: "website_url",
        label: "Website url",
        common: false,
      },
      {
        key: "booking_url",
        label: "Booking url",
        common: false,
      },
      {
        key: "pinterest_url",
        label: "Pinterest url",
        common: false,
      },
      {
        key: "facebook_url",
        label: "Facebook url",
        common: false,
      },
      {
        key: "twitter_url",
        label: "Twitter url",
        common: false,
      },
      {
        key: "instagram_url",
        label: "Instagram url",
        common: false,
      },
      {
        key: "yelp_url",
        label: "Yelp url",
        common: false,
      },
      {
        key: "laser_hair_removal",
        label: "Laser hair removal",
        common: false,
      },
      {
        key: "threading",
        label: "Threading",
        common: false,
      },
      {
        key: "permanent_makeup",
        label: "Permanent makeup",
        common: false,
      },
      {
        key: "other_service",
        label: "Other service",
        common: false,
      },
      {
        key: "google_plus_url",
        label: "Google plus url",
        common: false,
      },
      {
        key: "linkedin_url",
        label: "Linkedin url",
        common: false,
      },
      {
        key: "hair_extensions",
        label: "Hair extensions",
        common: false,
      },
      {
        key: "image_1_url",
        label: "Image 1 url",
        common: false,
      },
      {
        key: "image_2_url",
        label: "Image 2 url",
        common: false,
      },
      {
        key: "image_3_url",
        label: "Image 3 url",
        common: false,
      },
      {
        key: "image_4_url",
        label: "Image 4 url",
        common: false,
      },
      {
        key: "image_5_url",
        label: "Image 5 url",
        common: false,
      },
      {
        key: "image_6_url",
        label: "Image 6 url",
        common: false,
      },
      {
        key: "image_7_url",
        label: "Image 7 url",
        common: false,
      },
      {
        key: "image_8_url",
        label: "Image 8 url",
        common: false,
      },
      {
        key: "image_9_url",
        label: "Image 9 url",
        common: false,
      },
      {
        key: "image_10_url",
        label: "Image 10 url",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "email_address",
        label: "Email address",
        common: false,
      },
      {
        key: "approved",
        label: "Approved",
        common: false,
      },
      {
        key: "image_1_file_name",
        label: "image 1",
        common: false,
      },
      {
        key: "image_2_file_name",
        label: "image 2",
        common: false,
      },
      {
        key: "image_3_file_name",
        label: "image 3",
        common: false,
      },
      {
        key: "image_4_file_name",
        label: "image 4",
        common: false,
      },
      {
        key: "image_5_file_name",
        label: "image 5",
        common: false,
      },
      {
        key: "image_6_file_name",
        label: "image 6",
        common: false,
      },
      {
        key: "image_7_file_name",
        label: "image 7",
        common: false,
      },
      {
        key: "image_8_file_name",
        label: "image 8",
        common: false,
      },
      {
        key: "image_9_file_name",
        label: "image 9",
        common: false,
      },
      {
        key: "image_10_file_name",
        label: "image 10",
        common: false,
      },
      {
        key: "microblading",
        label: "Microblading",
        common: false,
      },
      {
        key: "reserved",
        label: "Reserved",
        common: false,
      },
      {
        key: "botox",
        label: "Botox",
        common: false,
      },
      {
        key: "tik_tok_url",
        label: "Tik tok url",
        common: false,
      },
      {
        key: "barber",
        label: "Barber",
        common: false,
      },
    ],
    export_type: "Update My Sola Websites Rejected",
    table_name: "update_my_sola_website_deleted_records",
  },
  connect_webpages: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "business_name",
        label: "Business Name",
        common: false,
      },
      {
        key: "subtitle",
        label: "Subtitle",
        common: false,
      },
      {
        key: "studio_number",
        label: "Studio Number",
        common: false,
      },
      {
        key: "website_url",
        label: "Website url",
        common: false,
      },
      {
        key: "booking_url",
        label: "Booking url",
        common: false,
      },
      {
        key: "pinterest_url",
        label: "Pinterest url",
        common: false,
      },
      {
        key: "facebook_url",
        label: "Facebook url",
        common: false,
      },
      {
        key: "twitter_url",
        label: "Twitter url",
        common: false,
      },
      {
        key: "instagram_url",
        label: "Instagram url",
        common: false,
      },
      {
        key: "yelp_url",
        label: "Yelp url",
        common: false,
      },
      {
        key: "google_plus_url",
        label: "Google plus url",
        common: false,
      },
      {
        key: "linkedin_url",
        label: "Linkedin url",
        common: false,
      },
      {
        key: "tik_tok_url",
        label: "Tik Tok url",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Web Pages",
    table_name: "connect_webpages",
  },
  update_connect_web_page_requests: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "business_name",
        label: "Business name",
        common: false,
      },
      {
        key: "subtitle",
        label: "Subtitle",
        common: false,
      },
      {
        key: "studio_number",
        label: "Studio Number",
        common: false,
      },
      {
        key: "website_url",
        label: "Website url",
        common: false,
      },
      {
        key: "booking_url",
        label: "Booking url",
        common: false,
      },
      {
        key: "pinterest_url",
        label: "Pinterest url",
        common: false,
      },
      {
        key: "facebook_url",
        label: "Facebook url",
        common: false,
      },
      {
        key: "twitter_url",
        label: "Twitter url",
        common: false,
      },
      {
        key: "instagram_url",
        label: "Instagram url",
        common: false,
      },
      {
        key: "yelp_url",
        label: "Yelp url",
        common: false,
      },
      {
        key: "google_plus_url",
        label: "Google plus url",
        common: false,
      },
      {
        key: "linkedin_url",
        label: "Linkedin url",
        common: false,
      },
      {
        key: "tik_tok_url",
        label: "Tik tok url",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Update Web Page Requests",
    table_name: "update_connect_web_page_requests",
  },
  update_connect_web_page_requests_deleted_records: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "business_name",
        label: "Business ame",
        common: false,
      },
      {
        key: "studio_number",
        label: "Studio Number",
        common: false,
      },
      {
        key: "website_url",
        label: "Website url",
        common: false,
      },
      {
        key: "booking_url",
        label: "Booking url",
        common: false,
      },
      {
        key: "pinterest_url",
        label: "Pinterest url",
        common: false,
      },
      {
        key: "facebook_url",
        label: "Facebook url",
        common: false,
      },
      {
        key: "twitter_url",
        label: "Twitter url",
        common: false,
      },
      {
        key: "instagram_url",
        label: "Instagram url",
        common: false,
      },
      {
        key: "yelp_url",
        label: "Yelp url",
        common: false,
      },
      {
        key: "google_plus_url",
        label: "Google plus url",
        common: false,
      },
      {
        key: "linkedin_url",
        label: "Linkedin url",
        common: false,
      },
      {
        key: "tik_tok_url",
        label: "Tik tok url",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Rejected Update Web Page Requests",
    table_name: "update_connect_web_page_requests_deleted_records",
  },
  /** Mobile App Start */
  brands: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
      {
        key: "website_url",
        label: "Website url",
        common: false,
      },
      {
        key: "white_image_file_name",
        label: "White image file name",
        common: false,
      },
      {
        key: "white_image_content_type",
        label: "White image content type",
        common: false,
      },
      {
        key: "white_image_file_size",
        label: "White image file size",
        common: false,
      },
      {
        key: "white_image_updated_at",
        label: "White image updated at",
        common: false,
      },
      {
        key: "introduction_video_heading_title",
        label: "Introduction video heading title",
        common: false,
      },
      {
        key: "events_and_classes_heading_title",
        label: "Events and classes heading title",
        common: false,
      },
      {
        key: "views",
        label: "Views",
        common: false,
      },
    ],
    export_type: "Brands",
    table_name: "brands",
  },
  class_images: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
      {
        key: "thumbnail_file_name",
        label: "Thumbnail",
        common: false,
      },
    ],
    export_type: "Class Images",
    table_name: "class_images",
  },
  deals: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "title",
        label: "Title",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "file_file_name",
        label: "File",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
      {
        key: "description",
        label: "Description",
        common: false,
      },
      {
        key: "is_featured",
        label: "Is featured",
        common: false,
      },
      {
        key: "hover_image_file_name",
        label: "Hover image",
        common: false,
      },
      {
        key: "more_info_url",
        label: "More info url",
        common: false,
      },
      {
        key: "views",
        label: "Views",
        common: false,
      },
    ],
    export_type: "Deals",
    table_name: "deals",
  },
  education_hero_images: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "action_link",
        label: "Action link",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
      {
        key: "position",
        label: "Position",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Education Hero Images",
    table_name: "education_hero_images",
  },
  /* event and classes */
  sola_classes: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "title",
        label: "Title",
        common: false,
      },
      {
        key: "city",
        label: "City",
        common: false,
      },
      {
        key: "state",
        label: "State",
        common: false,
      },
      {
        key: "postal_code",
        label: "Postal code",
        common: false,
      },
      {
        key: "location",
        label: "Location",
        common: false,
      },
      {
        key: "cost",
        label: "Cost",
        common: false,
      },
      {
        key: "start_date",
        label: "Start date",
        common: false,
      },
      {
        key: "start_time",
        label: "Start time",
        common: false,
      },
      {
        key: "file_file_name",
        label: "File",
        common: false,
      },
      {
        key: "link_text",
        label: "Link text",
        common: false,
      },
      {
        key: "link_url",
        label: "Link url",
        common: false,
      },
      {
        key: "latitude",
        label: "Latitude",
        common: false,
      },
      {
        key: "longitude",
        label: "Longitude",
        common: false,
      },
      {
        key: "is_featured",
        label: "Is featured",
        common: false,
      },
      {
        key: "description",
        label: "Description",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
      {
        key: "end_date",
        label: "End date",
        common: false,
      },
      {
        key: "end_time",
        label: "End time",
        common: false,
      },
      {
        key: "rsvp_email_address",
        label: "Rsvp email address",
        common: false,
      },
      {
        key: "rsvp_phone_number",
        label: "Rsvp phone number",
        common: false,
      },
      {
        key: "address",
        label: "Address",
        common: false,
      },
      {
        key: "file_text",
        label: "File text",
        common: false,
      },
      {
        key: "views",
        label: "views",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Event And Classes",
    table_name: "sola_classes",
  },
  home_buttons: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "position",
        label: "Position",
        common: false,
      },
      {
        key: "action_link",
        label: "Action link",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
    ],
    export_type: "Home Buttons",
    table_name: "home_buttons",
  },
  home_hero_images: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "position",
        label: "Position",
        common: false,
      },
      {
        key: "action_link",
        label: "Action link",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
    ],
    export_type: "Home Hero Images",
    table_name: "home_hero_images",
  },
  notifications: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "notification_text",
        label: "Notification text",
        common: false,
      },
      {
        key: "send_push_notification",
        label: "Send push notification",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "date_sent",
        label: "Date sent",
        common: false,
      },
      {
        key: "title",
        label: "Title",
        common: false,
      },
      {
        key: "send_at",
        label: "Send at",
        common: false,
      },
    ],
    export_type: "Notifications",
    table_name: "notifications",
  },
  product_informations: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "title",
        label: "Title",
        common: false,
      },
      {
        key: "description",
        label: "Description",
        common: false,
      },
      {
        key: "link_url",
        label: "Link url",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
      {
        key: "file_file_name",
        label: "File",
        common: false,
      },
    ],
    export_type: "Product Informations",
    table_name: "product_informations",
  },
  /* regions */
  sola_class_regions: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "position",
        label: "Position",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
    ],
    export_type: "Regions",
    table_name: "sola_class_regions",
  },
  side_menu_items: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "action_link",
        label: "Action link",
        common: false,
      },
      {
        key: "position",
        label: "Position",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Side Menu",
    table_name: "side_menu_items",
  },
  /* state_regions */
  sola_class_region_states: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "state",
        label: "State",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "State Regions",
    table_name: "state_regions",
  },
  tools: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "title",
        label: "Title",
        common: false,
      },
      {
        key: "description",
        label: "Description",
        common: false,
      },
      {
        key: "is_featured",
        label: "Is featured",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
      {
        key: "file_file_name",
        label: "File",
        common: false,
      },
      {
        key: "link_url",
        label: "Link url",
        common: false,
      },
      {
        key: "youtube_url",
        label: "Youtube url",
        common: false,
      },
      {
        key: "views",
        label: "Views",
        common: false,
      },
    ],
    export_type: "Tools And Resources",
    table_name: "tools",
  },
  videos: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "title",
        label: "Title",
        common: false,
      },
      {
        key: "description",
        label: "Description",
        common: false,
      },
      {
        key: "is_featured",
        label: "Is featured",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "youtube_url",
        label: "Youtube url",
        common: false,
      },
      {
        key: "duration",
        label: "Duration",
        common: false,
      },
      {
        key: "is_introduction",
        label: "Is introduction",
        common: false,
      },
      {
        key: "link_url",
        label: "Link url",
        common: false,
      },
      {
        key: "link_text",
        label: "Link text",
        common: false,
      },
      {
        key: "views",
        label: "views",
        common: false,
      },
      {
        key: "webinar",
        label: "Webinar",
        common: false,
      },
    ],
    export_type: "Videos",
    table_name: "videos",
  },
  /** Mobile App End */

  /* Frenchisee Website Start */
  franchise_articles: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "slug",
        label: "Slug",
        common: false,
      },
      {
        key: "title",
        label: "Title",
        common: false,
      },
      {
        key: "url",
        label: "Url",
        common: false,
      },
      {
        key: "summary",
        label: "Summary",
        common: false,
      },
      {
        key: "body",
        label: "Body",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
      {
        key: "author",
        label: "Author",
        common: false,
      },
      {
        key: "country",
        label: "Country",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "kind",
        label: "Kind",
        common: false,
      },
      {
        key: "thumbnail_file_name",
        label: "Thumbnail",
        common: false,
      },
    ],
    export_type: "Franchise Articles(Blog)",
    table_name: "franchise_articles",
  },
  franchising_forms: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "first_name",
        label: "First name",
        common: false,
      },
      {
        key: "last_name",
        label: "Last name",
        common: false,
      },
      {
        key: "email_address",
        label: "Email address",
        common: false,
      },
      {
        key: "phone_number",
        label: "Phone number",
        common: false,
      },
      {
        key: "multi_unit_operator",
        label: "Multi unit operator",
        common: false,
      },
      {
        key: "liquid_capital",
        label: "Liquid capital",
        common: false,
      },
      {
        key: "city",
        label: "City",
        common: false,
      },
      {
        key: "state",
        label: "State",
        common: false,
      },
      {
        key: "agree_to_receive_email",
        label: "Agree to receive email",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "utm_source",
        label: "Utm source",
        common: false,
      },
      {
        key: "utm_campaign",
        label: "Utm campaign",
        common: false,
      },
      {
        key: "utm_medium",
        label: "Utm medium",
        common: false,
      },
      {
        key: "utm_content",
        label: "Utm content",
        common: false,
      },
      {
        key: "utm_term",
        label: "Utm term",
        common: false,
      },
      {
        key: "country",
        label: "Country",
        common: false,
      },
    ],
    export_type: "Franchising Forms(Inquiries)",
    table_name: "franchising_inquiries",
  },
  /* Frenchisee Website End */

  /** Sola Pros Start */
  stylists: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "f_name",
        label: "First name",
        common: true,
      },
      {
        key: "l_name",
        label: "Last name",
        common: true,
      },
      {
        key: "url_name",
        label: "Url name",
        common: false,
      },
      {
        key: "email_address",
        label: "Email address",
        common: true,
      },
      {
        key: "phone_number",
        label: "Phone",
        common: true,
      },
      {
        key: "biography",
        label: "Biography",
        common: false,
      },
      {
        key: "status",
        label: "Status",
        common: true,
      },
      {
        key: "sola_pro_platform",
        label: "Sola pro platform",
        common: false,
      },
      {
        key: "sola_pro_version",
        label: "Sola pro version",
        common: false,
      },
      {
        key: "location_name",
        label: "Location",
        common: true,
      },
      {
        key: "business_name",
        label: "Business name",
        common: false,
      },
      {
        key: "studio_number",
        label: "Studio number",
        common: true,
      },
      {
        key: "work_hours",
        label: "Work hours",
        common: false,
      },
      {
        key: "accepting_new_clients",
        label: "Accepting new clients",
        common: false,
      },
      {
        key: "walkins",
        label: "Walkins",
        common: false,
      },
      {
        key: "total_booknow_bookings",
        label: "Total booknow bookings",
        common: false,
      },
      {
        key: "total_booknow_revenue",
        label: "Total booknow revenue",
        common: false,
      },
      {
        key: "booking_url",
        label: "Booking url",
        common: false,
      },
      {
        key: "send_a_message_button",
        label: "Send a message button",
        common: false,
      },
      {
        key: "hair",
        label: "Hair",
        common: false,
      },
      {
        key: "skin",
        label: "Skin",
        common: false,
      },
      {
        key: "nails",
        label: "Nails",
        common: false,
      },
      {
        key: "massage",
        label: "Massage",
        common: false,
      },
      {
        key: "microblading",
        label: "Microblading",
        common: false,
      },
      {
        key: "teeth_whitening",
        label: "Teeth whitening",
        common: false,
      },
      {
        key: "hair_extensions",
        label: "Hair extensions",
        common: false,
      },
      {
        key: "eyelash_extensions",
        label: "Eyelash extensions",
        common: false,
      },
      {
        key: "makeup",
        label: "Makeup",
        common: false,
      },
      {
        key: "tanning",
        label: "Tanning",
        common: false,
      },
      {
        key: "waxing",
        label: "Waxing",
        common: false,
      },
      {
        key: "barber",
        label: "Barber",
        common: false,
      },
      {
        key: "botox",
        label: "Botox",
        common: false,
      },
      {
        key: "brows",
        label: "Brows",
        common: false,
      },
      {
        key: "facebook_url",
        label: "Facebook url",
        common: false,
      },
      {
        key: "google_plus_url",
        label: "Google plus url",
        common: false,
      },
      {
        key: "instagram_url",
        label: "Instagram url",
        common: false,
      },
      {
        key: "pinterest_url",
        label: "Pinterest url",
        common: false,
      },
      {
        key: "twitter_url",
        label: "Twitter url",
        common: false,
      },
      {
        key: "yelp_url",
        label: "Yelp url",
        common: false,
      },
      {
        key: "tik_tok_url",
        label: "Tik tok url",
        common: false,
      },
    ],
    export_type: "Sola Professional",
    table_name: "stylists",
  },
  /** Sola Pros End */
  articles: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "title",
        label: "Title",
        common: false,
      },
      {
        key: "url_name",
        label: "Url name",
        common: false,
      },
      {
        key: "summary",
        label: "Summary",
        common: false,
      },
      {
        key: "body",
        label: "Body",
        common: false,
      },
      {
        key: "article_url",
        label: "Article url",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
      {
        key: "legacy_id",
        label: "Legacy",
        common: false,
      },
      {
        key: "display_setting",
        label: "Display setting",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
      {
        key: "location_name",
        label: "Location",
        common: false,
      },
    ],
    export_type: "Articles (News)",
    table_name: "articles",
  },
  blogs: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "title",
        label: "Title",
        common: false,
      },
      {
        key: "url_name",
        label: "Url name",
        common: false,
      },
      {
        key: "image_file_name",
        label: "Image",
        common: false,
      },
      {
        key: "summary",
        label: "Summary",
        common: false,
      },
      {
        key: "body",
        label: "Body",
        common: false,
      },
      {
        key: "author",
        label: "Author",
        common: false,
      },
      {
        key: "legacy_id",
        label: "Legacy",
        common: false,
      },
      {
        key: "carousel_image_file_name",
        label: "Carousel image",
        common: false,
      },
      {
        key: "carousel_text",
        label: "Carousel Text",
        common: false,
      },
      {
        key: "fb_conversion_pixel",
        label: "Fb conversion pixel",
        common: false,
      },
      {
        key: "status",
        label: "Status",
        common: false,
      },
      {
        key: "publish_date",
        label: "Publish date",
        common: false,
      },
      {
        key: "contact_form_visible",
        label: "Contact form visible",
        common: false,
      },
      {
        key: "meta_description",
        label: "Meta description",
        common: false,
      },
      {
        key: "canonical_url",
        label: "Canonical url",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Blogs",
    table_name: "blogs",
  },
  categories: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "slug",
        label: "Slug",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Categories",
    table_name: "categories",
  },
  tags: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Tags",
    table_name: "tags",
  },
  callfire_logs: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "data",
        label: "Data",
        common: false,
      },
      {
        key: "status",
        label: "Status",
        common: false,
      },
      {
        key: "kind",
        label: "Kind",
        common: false,
      },
      {
        key: "action",
        label: "Action",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "callfire logs",
    table_name: "callfire_logs",
  },

  /** RM Events  */
  rm_events: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "status",
        label: "Status",
        common: false,
      },
      {
        key: "status_message",
        label: "Status Message",
        common: false,
      },
      {
        key: "body",
        label: "Body",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Rent Manager Events",
    table_name: "rent_manager_events",
  },
  locations: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Location Name",
        common: true,
      },
      {
        key: "url_name",
        label: "Url name",
        common: false,
      },
      {
        key: "address_1",
        label: "Address 1",
        common: true,
      },
      {
        key: "address_2",
        label: "Address 2",
        common: true,
      },
      {
        key: "city",
        label: "City",
        common: true,
      },
      {
        key: "state",
        label: "State/Province",
        common: true,
      },
      {
        key: "postal_code",
        label: "Postal code",
        common: true,
      },
      {
        key: "status",
        label: "Status",
        common: false,
      },
      {
        key: "email_address_for_inquiries",
        label: "Email for Contact Inquiries",
        common: true,
      },
      {
        key: "phone_number",
        label: "Phone number",
        common: false,
      },
      {
        key: "general_contact_name",
        label: "General contact name",
        common: true,
      },
      {
        key: "description",
        label: "Description",
        common: false,
      },
      {
        key: "move_in_special",
        label: "Move in special",
        common: false,
      },
      {
        key: "open_house",
        label: "Special Callout",
        common: false,
      },
      {
        key: "facebook_url",
        label: "Facebook url",
        common: false,
      },
      {
        key: "twitter_url",
        label: "Twitter url",
        common: false,
      },
      {
        key: "instagram_url",
        label: "Instagram url",
        common: false,
      },
      {
        key: "yelp_url",
        label: "Yelp url",
        common: false,
      },
      {
        key: "msa_name",
        label: "MSA",
        common: false,
        hide_franchisee: true,
      },
      {
        key: "franchisee",
        label: "Franchisee",
        common: false,
        hide_franchisee: true,
      },
      {
        key: "store_id",
        label: "Sola ID",
        common: true,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Locations",
    table_name: "connect_locations",
    exclude_columns: [
      "id",
      "name",
      "url_name",
      "address_1",
      "address_2",
      "city",
      "state",
      "postal_code",
      "status",
      "email_address_for_inquiries",
      "phone_number",
      "general_contact_name",
      "description",
      "move_in_special",
      "open_house",
      "facebook_url",
      "twitter_url",
      "instagram_url",
      "yelp_url",
      "msa_name",
      "franchisee",
      "store_id",
      "created_at",
      "updated_at",
    ],
    join_tables: [
      {
        table: "locations",
        fields: [
          "id",
          "name",
          "url_name",
          "address_1",
          "address_2",
          "city",
          "state",
          "postal_code",
          "status",
          "email_address_for_inquiries",
          "phone_number",
          "general_contact_name",
          "description",
          "move_in_special",
          "open_house",
          "facebook_url",
          "twitter_url",
          "instagram_url",
          "yelp_url",
          "store_id",
          "created_at",
          "updated_at",
        ],
        first_table_join_field: "cms_location_id",
        second_table_join_field: "id",
        is_deleted: true,
        include: [
          {
            table: "admins",
            fields: [["franchisee", "email"]],
            first_table_join_field: "admin_id",
            second_table_join_field: "id",
          },
          {
            table: "msas",
            fields: [["msa_name", "name"]],
            first_table_join_field: "msa_id",
            second_table_join_field: "id",
          },
        ],
      },
    ],
  },
  connect_associations: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "association_id",
        label: "Association id",
        common: false,
      },
      {
        key: "association_name",
        label: "Association name",
        common: false,
      },
      {
        key: "bussiness_name",
        label: "Bussiness name",
        common: false,
      },
      {
        key: "incorporation_state",
        label: "Incorporation state",
        common: false,
      },
      {
        key: "notice_address_1",
        label: "Notice address 1",
        common: false,
      },
      {
        key: "notice_address_2",
        label: "Notice address 2",
        common: false,
      },
      {
        key: "notice_city",
        label: "Notice city",
        common: false,
      },
      {
        key: "notice_postal_code",
        label: "Notice postal code",
        common: false,
      },
      {
        key: "notice_state",
        label: "Notice state",
        common: false,
      },
      {
        key: "notice_country",
        label: "Notice country",
        common: false,
      },
      {
        key: "association_description",
        label: "Association description",
        common: false,
      },
      {
        key: "locations",
        label: "Locations",
        common: false,
      },
      {
        key: "owners",
        label: "Owners",
        common: false,
      },
      {
        key: "users",
        label: "Users",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Associations",
    table_name: "connect_associations",
  },
  // For FQS
  faq: {
    export_fields: [
      {
        key: "faq_id",
        label: "Id",
        common: false,
      },
      {
        key: "question",
        label: "Question",
        common: false,
      },
      {
        key: "answer",
        label: "Answer",
        common: false,
      },
      {
        key: "location_id",
        label: "Location",
        common: false,
      },
    ],
    export_type: "Faqs",
    table_name: "connect_faqs",
  },
  download_center: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "export_type",
        label: "Export Type",
        common: false,
      },
      {
        key: "table_name",
        label: "Table Name",
        common: false,
      },
      {
        key: "fields_to_export",
        label: "Fields To Export",
        common: false,
      },
      {
        key: "user_id",
        label: "User Id",
        common: false,
      },
      {
        key: "request_time",
        label: "Request Time",
        common: false,
      },
      {
        key: "status",
        label: "Status",
        common: false,
      },
      {
        key: "url",
        label: "Url",
        common: false,
      },
      {
        key: "created_at",
        label: "Created At",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated At",
        common: false,
      },
      {
        key: "selected_records",
        label: "Selected Records",
        common: false,
      },
      {
        key: "export_from_date",
        label: "Export From Date",
        common: false,
      },
      {
        key: "export_to_date",
        label: "Export To Date",
        common: false,
      },
      {
        key: "additional_data",
        label: "Additional Data",
        common: false,
      },
      {
        key: "locations_ids",
        label: "Locations Ids",
        common: false,
      },
      {
        key: "ruby_api_data",
        label: "Ruby Api Data",
        common: false,
      },
    ],
    export_type: "Download Center",
    table_name: "download_center",
  },
  connect_testimonials: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "name",
        label: "Name",
        common: false,
      },
      {
        key: "text",
        label: "Text",
        common: false,
      },
      {
        key: "region",
        label: "Region",
        common: false,
      },
      {
        key: "title",
        label: "Title",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
      {
        key: "updated_at",
        label: "Updated at",
        common: false,
      },
    ],
    export_type: "Testimonials",
    table_name: "connect_testimonials",
  },
  connect_lead_tracking: {
    export_fields: [
      {
        key: "id",
        label: "Id",
        common: false,
      },
      {
        key: "location_name",
        label: "Location",
        common: false,
      },
      {
        key: "services",
        label: "Services",
        common: false,
      },
      {
        key: "utm_parameters",
        label: "UTM Parameters",
        common: false,
      },
      {
        key: "created_at",
        label: "Created at",
        common: false,
      },
    ],
    join_tables: [
      {
        table: "locations",
        fields: ["name"],
        first_table_join_field: "location_id",
        second_table_join_field: "id",
      },
    ],
    export_type: "Analytics",
    table_name: "connect_lead_tracking",
  },
};
