import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomAutoSearch from "components/CustomAutoSearch";
import { adminFranchisie, systemAdmin } from "helper/services";

function Address(props: any): JSX.Element {
  const {
    editPermission,
    onChangeField,
    locationDetail,
    onChangeAutoComplete,
    getAccessTypePermission,
    errors,
  } = props;

  return (
    <Card id="address" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Address</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Address 1"
              variant="standard"
              fullWidth
              name="address_1"
              placeholder="Address 1"
              disabled={!editPermission}
              onChange={onChangeField}
              value={locationDetail?.address_1 || ""}
              // error={errors && errors.address_1 ? true : false}
              // helperText={errors && errors.address_1}
              // required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Address 2"
              variant="standard"
              fullWidth
              name="address_2"
              placeholder="Address 2"
              disabled={!editPermission}
              onChange={onChangeField}
              value={locationDetail?.address_2 || ""}
              // error={errors && errors.address_2 ? true : false}
              // helperText={errors && errors.address_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="City"
              variant="standard"
              fullWidth
              name="city"
              placeholder="City"
              disabled={!editPermission}
              onChange={onChangeField}
              value={locationDetail?.city || ""}
              // error={errors && errors.city ? true : false}
              // helperText={errors && errors.city}
              // required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="State(Province in CA)"
              variant="standard"
              fullWidth
              name="state"
              placeholder="State(Province in CA)"
              disabled={!editPermission}
              onChange={onChangeField}
              value={locationDetail?.state || ""}
              error={errors && errors.state}
              helperText={errors && errors.state}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomAutoSearch
              idKey="id"
              nameKey="name"
              fieldLabel="Country"
              apiName="countries"
              responseKey="countries"
              name="countries"
              disabled={!editPermission}
              value={locationDetail?.countries || null}
              onChange={onChangeAutoComplete}
              error={errors && errors.countries}
              helperText={errors && errors.countries}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Postal Code"
              variant="standard"
              fullWidth
              name="postal_code"
              placeholder="Postal Code"
              disabled={!editPermission}
              onChange={onChangeField}
              value={locationDetail?.postal_code || ""}
              // error={errors && errors?.postal_code ? true : false}
              // helperText={errors && errors?.postal_code}
              // required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Latitude"
              variant="standard"
              fullWidth
              name="latitude"
              placeholder="Latitude"
              onChange={onChangeField}
              disabled={!editPermission}
              value={locationDetail?.latitude || ""}
              // error={errors && errors.latitude ? true : false}
              // helperText={errors && errors.latitude}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Longitude"
              variant="standard"
              fullWidth
              name="longitude"
              placeholder="Longitude"
              disabled={!editPermission}
              onChange={onChangeField}
              value={locationDetail?.longitude || ""}
              // error={errors && errors.longitude ? true : false}
              // helperText={errors && errors.longitude}
            />
          </Grid>
          {systemAdmin() && (
            <Grid item xs={12} sm={6}>
              <MDInput
                label="Custom Maps URL"
                variant="standard"
                fullWidth
                multiline
                rows={3}
                name="custom_maps_url"
                placeholder="Custom Maps URL"
                onChange={onChangeField}
                value={locationDetail?.custom_maps_url || ""}
                // error={errors && errors.custom_maps_url ? true : false}
                // helperText={errors && errors.custom_maps_url}
                disabled={getAccessTypePermission("location_general", "edit_access")}
              />
            </Grid>
          )}
          {adminFranchisie && ( // SD-3391
            <Grid item xs={12} sm={6}>
              <MDInput
                label="Map / GMB URL"
                placeholder="Map / GMB URL"
                fullWidth
                variant="standard"
                name="gmb_map_url"
                onChange={onChangeField}
                value={locationDetail?.gmb_map_url || ""}
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Address;
